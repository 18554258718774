/**
 * Created by Asad on 10/16/2019.
 */
export default {
    data(){
        return {
            loading: false,
            isFullPage: false,
            success: false,
            message: '',
            changed_id: -1,
        }
    },
    methods: {
        showSuccessMessage(msg){
            this.success = true;
            let app = this;
            this.message = msg;
            setTimeout(function () {
                app.success = false;
            }, 3000);
        }, showSuccessMessageWithID(msg, id){
            this.success = true;
            let app = this;
            this.message = msg;
            this.changed_id = id;
            setTimeout(function () {
                app.success = false;
                app.changed_id = -1;
            }, 3000);
        },
        startLoading(){
            this.loading = true;
        }, endLoading(){
            this.loading = false;
        },

        requestStatusChanged(){
            this.loading = !this.loading;
        }

    }
}