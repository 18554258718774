<template>
    <div class="container-fluid pl-5 pr-5 content-container">
        <div class="row">
            <div class="col-sm-12 bg-light shadow  p-3">
                <p class="heading">Shopping Cart</p>
                <hr/>


                <div class="table-responsive" v-if="$store.getters.cart.items>0">
                    <table class="table table-hover">
                        <tr>
                            <th class="border-top-0">#</th>
                            <th class="border-top-0">Item</th>
                            <th class="border-top-0">Price</th>
                            <th class="border-top-0">Quantity</th>
                            <th class="border-top-0">Total Price</th>
                            <!--<th class="border-top-0"></th>-->
                        </tr>
                        <tr v-for="(item,index) in $store.getters.cart.list" v-bind:key="index">
                            <th>{{index + 1}}</th>
                            <td>{{item.item_name}}</td>
                            <td>${{item.price}}</td>
                            <td><input type="number" :max="item.max" min="0" @change="updatePrice"
                                       v-model="item.quantity"
                                       class="form-control"
                                       style="width: 100px">
                            </td>
                            <td>${{(item.price * item.quantity).toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <th colspan="3">Total</th>
                            <th>{{$store.getters.cart.items}}</th>
                            <th>${{$store.getters.cart.price}}</th>
                        </tr>
                    </table>
                </div>
                <div class="alert alert-info" v-else="">Your cart is empty</div>

                <hr/>
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <p class="heading">Delivery Information</p>
                        <delivery-address v-on:selected="deliverySelected"></delivery-address>
                    </div>
                    <div class="col-sm-6">
                        <p class="heading">Billing Information</p>
                        <billing-address v-on:selected="billingSelected"></billing-address>
                    </div>

                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <input type="checkbox" v-model="same_as_delivery"> Use delivery address as billing address
                    </div>
                </div>

                <input type="button" v-if="$store.getters.cart.items>0" @click="previewOrder"
                       class="btn btn-outline-info btn-sm mb-2 mr-2 "
                       value="Preview Order">
                <router-link :to="{name:'shop'}" class="btn btn-sm btn-outline-info mb-2 shadow">View Products
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
    import Loader from '../../loader'
    import BillingAddress from '@/views/shop/BillingInformation'
    import DeliveryAddress from '@/views/shop/DeliveryInformation'

    export default{
        extends: Loader,
        components: {BillingAddress, DeliveryAddress},
        data(){
            return {

                same_as_delivery: false, empty_billing: true,
            }
        },

        computed: {},
        methods: {
            isValidBilling(data){

                if (data == undefined) {
                    return false;
                }
                if (data == null) {
                    return false;
                }

                return true;

            },
            validateOrder(){

                if (this.$store.getters.cart.delivery_address == undefined) {
                    return 'Please select delivery address, add new if you have not added it yet';
                }
                if (!this.same_as_delivery && this.$store.getters.cart.billing_address == undefined) {
                    return 'Either check the option to use delivery address as billing address or select billing address';
                }
                return true;

            },
            deliverySelected(data){
                this.$store.commit('setDeliveryAddressInCart', data);
            },
            billingSelected(data){
                if (data == undefined) {
                    return;
                }
                this.same_as_delivery = false;
                let app = this;
                setTimeout(function () {
                    app.$store.commit('setBillingAddressInCart', data);
                }, 500);

            },

            previewOrder(){

                let validation = this.validateOrder();
                if (validation != true) {
                    alert(validation);
                    return;
                }

                this.$router.push({'name': 'cart.preview'});

            },

            updatePrice(){
                let price = 0;
                let quantity = 0;

                for (let i = 0; i < this.$store.getters.cart.list.length; i++) {
                    price += parseFloat(this.$store.getters.cart.list[i].price) * parseInt(this.$store.getters.cart.list[i].quantity);

                    quantity += parseInt(this.$store.getters.cart.list[i].quantity);

                }

                this.$store.getters.cart.price = price;
                this.$store.getters.cart.items = quantity;
            }
        },
        watch: {
            same_as_delivery(value){

                this.$store.commit('setSameAsDeliveryInCart', value);
            }
        },
        mounted(){
            this.same_as_delivery = this.$store.getters.cart.same_as_delivery;
        }
    }
</script>