/**
 * Created by Asad on 12/27/2019.
 */

import {Paths} from "@/paths";
export default {
    methods: {
        async statesList(){

            return await this.axios.get(Paths.state.index);
        },
        // async validateZip(zipCode, state_id,city_id){
        //
        //     return await this.axios.post(Paths.state.validate_zip, {zip: zipCode, state_id: state_id});
        // },
        account_types(){
           return {
                1: 'Childcare center',
                    2: 'Restaurant',
                    3: 'Schools',
                    4: 'Non profits',
                    5: 'Families',
            };
        },
        delivery_types(){
            return{
                1:'Delivery',
                2:'Pickup',
            }
        }
    }
}